@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply font-sans text-base;
    }
}

@font-face {
    font-family: OpenSans;
    src: url('./fonts/OpenSans-Regular.woff2') format('woff2');
    font-weight: 400;
}

@font-face {
    font-family: OpenSans;
    src: url('./fonts/OpenSans-Bold.woff2') format('woff2');
    font-weight: 400;
}

@font-face {
    font-family: OpenSansVariable;
    src: url('./fonts/OpenSans-VariableFont_wdth,wght.woff2') format('woff2');
    font-weight: 100 1000;
    font-stretch: 75% 200%;
}

@font-face {
    font-family: Jost;
    src: url('./fonts/Jost-Regular.woff2') format('woff2');
    font-weight: 400;
}

@font-face {
    font-family: Jost;
    src: url('./fonts/Jost-Bold.woff2') format('woff2');
    font-weight: 400;
}

@font-face {
    font-family: JostVariable;
    src: url('./fonts/Jost-VariableFont_wght.woff2') format('woff2');
    font-weight: 100 1000;
    font-stretch: 75% 200%;
}

input:checked + svg {
    display: block;
}

.vis-tooltip {
    @apply absolute bg-neutral-100 text-neutral-400 p-2 text-sm tracking-wider;

    max-width: 200px;
}

.external-link:after {
    content: ' ↗';
}

:root {
    --ds-border-discovery: #2ca4ab;
    --ds-background-discovery-bold: #2a5169;
    --ds-background-inverse-subtle: #176595;
    --ds-background-inverse-subtle-hovered: #2aa3dc;
    --ds-background-inverse-subtle-pressed: #19c1df;
}

.overflow-always-hidden {
    overflow: hidden;
}

.overflow-y-always-hidden {
    overflow-y: hidden;
}

@media print {
    .page-break {
        page-break-before: always;
    }

    .vis-network {
        transform: scaleX(1.1);
    }
    .grid-cols-3 {
        grid-template-columns: 10em 1fr 1fr;
    }
    .overflow-hidden {
        overflow: unset;
    }
    .overflow-y-auto {
        overflow: unset;
    }
    .avoid-break-children > * {
        break-inside: avoid;
    }
    .profile-pic {
        overflow: hidden;
        max-height: 40em;
        max-width: 25em;
    }
}

@page {
    size: A4 portrait;
    margin: 0;
}

@keyframes newRowElem {
    0% {
        border-color: transparent;
    }
    100% {
        border-color: #7cbd7b;
    }
}

.animate-new-row {
    animation-name: newRowElem;
    animation-duration: 1s;
    animation-iteration-count: 6;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
}

svg.recharts-surface * {
    outline: none;
}
