.react-calendar-timeline .rct-dateHeader-primary {
    background-color: rgb(1, 54, 79);
}

.react-calendar-timeline .rct-header-root {
    background-color: rgb(1, 54, 79);
    border-top-left-radius: 0.5rem;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
    background: white;
    border-top: 1px solid lightgray;
    border-left: 2px solid rgb(1, 54, 79);
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
    border-top: 1px solid lightgray;
    border-left: 2px solid rgb(1, 54, 79);
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    background: white;
    border-top: 1px solid lightgray;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
    border-top: 1px solid lightgray;
}

.sticky-timeline-header {
    position: sticky !important;
    top: 0 !important;
    z-index: 2 !important;
}

.button-orbis {
    background: #c3ecff;
    color: #3ca4d4;
    font-weight: 600 !important;
    text-transform: uppercase;
}

.button-ring-orbis {
    border: 0px solid white !important;
    outline: #3ca4d4 solid 2px !important;
}

.button-orbis:not(:disabled):hover {
    background: #3ca4d4;
    color: white;
}

.button-companyhouse {
    background: #fbcfe8;
    color: #db2777;
    font-weight: 600 !important;
    text-transform: uppercase;
}

.button-ring-companyhouse {
    border: 0px solid white !important;
    outline: #db2777 solid 2px !important;
}

.button-companyhouse:not(:disabled):hover {
    background: #db2777;
    color: white;
}

.button-socialMedia {
    background: #fecaca;
    color: #dc2626;
    font-weight: 600 !important;
    text-transform: uppercase;
}

.button-ring-socialMedia {
    border: 0px solid white !important;
    outline: #dc2626 solid 2px !important;
}

.button-socialMedia:not(:disabled):hover {
    background: #dc2626;
    color: white;
}

.button-userInput {
    background: #8ed2c6;
    color: #173548;
    font-weight: 600 !important;
    text-transform: uppercase;
}

.button-ring-userInput {
    border: 0px solid white !important;
    outline: #173548 solid 2px !important;
}

.button-userInput:not(:disabled):hover {
    background: #2ca4ab;
    color: white;
}

.button-ring-all {
    border: 0px solid white !important;
    outline: #3ca4d4 solid 2px !important;
}

.react-calendar-timeline .rct-scroll {
    overflow-x: hidden !important;
}
